import React, { useState, useEffect } from "react";
import { Button, Divider, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import axios from "axios"
import { Auth } from "aws-amplify";
import { CSVLink } from "react-csv";
import FilterListIcon from '@mui/icons-material/FilterList';
// import AbcIcon from '@mui/icons-material/Abc';
// import SchoolIcon from '@mui/icons-material/School';
// import PinIcon from '@mui/icons-material/Pin';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import Table from "../../TableComponent";

const Students = ({facInfo}) => {
  const [studentsData, setStudentsData] = useState([])
  
  useEffect(() => {
    Auth.currentSession()
    .then((data) => {
      const jwtToken = data.idToken.jwtToken;
      
      const getStudentsData = async(facId, schoolId) => {
        try {
          const response =  await axios.get("https://zk1128nq2f.execute-api.ap-south-1.amazonaws.com/alpha/facilitator/"+facId+"/students?schoolId="+schoolId, {
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': jwtToken
              }
          });
          // console.log("Students Data: ",response.data["students"])
          // adding full name by combining firstName and lastName
          const key = response.data["allStudents"].length===0 ? "students" : "allStudents"
          const studentsData = response.data[key].map(item => ({
            ...item, 
            "fullName": item["firstName"] + " " + item["lastName"],
            // "classSection": item["std"] + "-" + item["divn"]
          }))
          setStudentsData(studentsData)
        }
        catch (error) {
            console.log(error)
        }
      }

      const facId = facInfo.facilitator[0].facId;
      const schools = facInfo.facilitator[0].schools
      // getting first school in alphabetical order same as shown in select school dropdown
      const schoolId = schools.filter(item => item.name === schools.map(item => item.name).sort()[0])[0].id;
      setSchool(schools.map(item => item.name).sort()[0])
      // console.log("facId and schoolId: ", facId, schoolId)
      getStudentsData(facId, schoolId)
      
    })
    .catch((err) => console.log(err));
    
  }, [facInfo]);
  
  const columns = [
    {
      key: "fullName",
      headerName: "Student Name",
      width: 80
    },
    {
      key: "studId",
      headerName: "Student PEN",
      width: 80
    },
    {
      key: "std",
      headerName: "Class",
      width: 30
    },
    { 
      key: "divn", 
      headerName: "Section", 
      width: 30 
    },
    // { 
    //   key: "classSection", 
    //   headerName: "Class", 
    //   width: 30 
    // },
    { 
      key: "rollNo", 
      headerName: "Roll No", 
      width: 30 
    },
    { 
      key: "gender", 
      headerName: "Gender", 
      width: 30 
    }
  ];

  const headers = [
    { label: "Class", key: "std" },
    { label: "Section", key: "divn" },
    { label: "Full Name of Student", key: "fullName" },
    { label: "Gender", key: "gender" },
    { label: "Student PEN (Maximum 12 digit)", key: "studId"},
    { label: "Roll No", key: "rollNo" }
  ];

  const orderBy = ["rollNo"];
  const [std, setStd] = useState("");
  const [divn, setDivn] = useState("");
  const [school, setSchool] = useState("");
  
  const handleStdChange = (e) => {
    setStd(e.target.value);
  };
  
  const handleDivnChange = (e) => {
    setDivn(e.target.value);
  };

  const handleSchoolChange = (e) => {
    setStd("");
    setDivn("");
    setStudentsData([])
    setSchool(e.target.value);
    if(e.target.value!==""){
      const facId = facInfo.facilitator[0].facId;
      const schoolId = facInfo.facilitator[0].schools.filter(item => item.name === e.target.value)[0].id
      Auth.currentSession()
      .then((data) => {
        const jwtToken = data.idToken.jwtToken;
        
        const getStudentsData = async(facId, schoolId) => {
          try {
            const response =  await axios.get("https://zk1128nq2f.execute-api.ap-south-1.amazonaws.com/alpha/facilitator/"+facId+"/students?schoolId="+schoolId, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': jwtToken
                }
            });
            // console.log("Students Data: ",response.data["students"])
            // adding full name by combining firstName and lastName
            const studentsData = response.data["students"].map(item => ({
              ...item, 
              "fullName": item["firstName"] + " " + item["lastName"],
              // "classSection": item["std"] + "-" + item["divn"]
            }))
            setStudentsData(studentsData)
          }
          catch (error) {
            console.log(error)
          }
        }
        getStudentsData(facId, schoolId)
      })
      .catch((err) => console.log(err));
    }
  };
  
  // const handleStdChipDelete = () => {
  //   setStd("");
  // };
  
  // const handleDivnChipDelete = () => {
  //   setDivn("");
  // };

  // const handleSchoolChipDelete = () => {
  //   setStd("");
  //   setDivn("");
  //   setSchool("");
  //   setStudentsData([])
  // };
  
  var counts = {studentCount: 0, enRecCount: 0, hiRecCount: 0}
  if(school) {
    var filteredSchoolFacInfo = facInfo.facilitator[0].schools.filter(item => item.name === school)[0].classes
    var filteredClassFacInfo = filteredSchoolFacInfo
    
    if(std!=="" && divn==="") filteredClassFacInfo = filteredSchoolFacInfo.filter(item => item.std === std)
    else if(std==="" && divn!=="") filteredClassFacInfo = filteredSchoolFacInfo.filter(item => item.divn === divn)
    else if(std!=="" && divn!=="") filteredClassFacInfo = filteredSchoolFacInfo.filter(item => item.std === std && item.divn === divn)
    
    counts = filteredClassFacInfo.reduce((acc, item) => {
      return {
        studentCount: acc.studentCount + item.studentCount,
        enRecCount: acc.enRecCount + item.enRecCount,
        hiRecCount: acc.hiRecCount + item.hiRecCount
      }
    }, {studentCount: 0, enRecCount: 0, hiRecCount: 0})
  }
  // console.log(counts)
  
  var filteredStudentsData
  if(std==="" && divn===""){
    filteredStudentsData = studentsData
    // filteredSchoolFacInfo
  }
  else if(std!=="" && divn===""){
    filteredStudentsData = [...studentsData.filter(item => item.std === std)]
  }
  else if(std==="" && divn!==""){
    filteredStudentsData = [...studentsData.filter(item => item.divn === divn)]
  }
  else{
    filteredStudentsData = [...studentsData.filter(item => item.std === std && item.divn === divn)]
  }
  // console.log(studentsData)
  // console.log(filteredStudentsData)

  return (
    <div className="studentsContent innerContent">
      <h2 style={{textAlign: "center", color: "#0288D1", borderRadius: "15px", border: "1px solid"}}>Students Data{facInfo.test ? <span> - {facInfo.test.testName}</span>: ""}</h2>
      {(facInfo.test && facInfo.test.status===0) && 
        <h4 style={{paddingTop:"1rem", color: "#dc3545"}}>
          Students can only be added from the principal's login. Once the students have been added, they will be visible here but not in the App. Last date to finalize student list is {new Date(facInfo.test.tentativeStartDate).toLocaleDateString("en-GB", { dateStyle: "long" })} after which the student list will also be visible in the App.
        </h4>
      }
      <div className="countTileContainer">
        <div className="countTile">
          <div className="countTileLeft">Total Students Count:</div>
          <div className="countTileRight">{counts.studentCount.toLocaleString("hi")}</div>
        </div>
        <div className="countTile">
          <div className="countTileLeft">Total Students Tested - English:</div>
          <div className="countTileRight">{counts.enRecCount.toLocaleString("hi")}</div>
        </div>
        <div className="countTile">
          <div className="countTileLeft">Total Students Tested - Hindi:</div>
          <div className="countTileRight">{counts.hiRecCount.toLocaleString("hi")}</div>
        </div>
      </div>
      <div className="filters">
        <div className="forms">
          <h4 style={{display: "flex", justifyContent: "center", alignItems: "center", gap: "0.25rem"}}>
            <FilterListIcon fontSize="small"/>
            <span style={{height: "100%"}}>Filters:</span> 
          </h4>
          <FormControl sx={{ m: 1, minWidth: 100 }} size="small">
            <InputLabel id="schoolFilterLabel">School</InputLabel>
            <Select
              labelId="schoolFilterLabel"
              id="schoolFilter"
              value={school}
              onChange={handleSchoolChange}
              autoWidth
              label="School Name"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {/* getting all unique divn values from data */}
              { facInfo &&
                [...new Set(facInfo.facilitator[0].schools.map(item => item.name))].sort().map((item, i) => {
                return <MenuItem key={i} value={item}>{item}</MenuItem>
                })
              }
            </Select>
          </FormControl>
          <FormControl sx={{ m: 1, minWidth: 85}} size="small">
            <InputLabel id="stdFilterLabel">Class</InputLabel>
            <Select
              labelId="stdFilterLabel"
              id="stdFilter"
              value={std}
              onChange={handleStdChange}
              autoWidth
              label="Class"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {/* getting all unique std values from data */}
              {divn==="" &&
                [...new Set(studentsData.map(item => item.std))].sort().map((item, i) => {
                return <MenuItem key={i} value={item}>{item}</MenuItem>
                })
              }
              {divn!=="" &&
                [...new Set(studentsData.filter(item => item.divn === divn).map(item => item.std))].sort().map((item, i) => {
                return <MenuItem key={i} value={item}>{item}</MenuItem>
                })
              }
            </Select>
          </FormControl>
          <FormControl sx={{ m: 1, minWidth: 100 }} size="small">
            <InputLabel id="divnFilterLabel">Section</InputLabel>
            <Select
              labelId="divnFilterLabel"
              id="divnFilter"
              value={divn}
              onChange={handleDivnChange}
              autoWidth
              label="Section"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {/* getting all unique divn values from data */}
              {std==="" &&
                [...new Set(studentsData.map(item => item.divn))].sort().map((item, i) => {
                return <MenuItem key={i} value={item}>{item}</MenuItem>
                })
              }
              {std!=="" &&
                [...new Set(studentsData.filter(item => item.std === std).map(item => item.divn))].sort().map((item, i) => {
                return <MenuItem key={i} value={item}>{item}</MenuItem>
                })
              }
            </Select>
          </FormControl>
        </div>
        {/* {(std || divn || school) &&
          <div className="currentFilters">
            <Typography variant="body1" gutterBottom>
              Current Filters: 
            </Typography>
            {school && 
              <Chip 
                variant="outlined" 
                color="info"  
                onDelete={handleSchoolChipDelete} 
                icon={<SchoolIcon />}
                label={school}
              />
            }
            {std && 
              <Chip 
                variant="outlined" 
                color="info"  
                onDelete={handleStdChipDelete} 
                icon={<PinIcon />}
                label={std}
              />
            }
            {divn &&
              <Chip 
                variant="outlined" 
                color="info"  
                onDelete={handleDivnChipDelete} 
                icon={<AbcIcon />}
                label={divn} 
              />
            }
          </div>
        } */}
      </div>
      <div className="tabs">
        <Button variant="contained" color="info" startIcon={<FileDownloadIcon />} sx={{ placeSelf: "start" }}>
          <CSVLink data={studentsData.filter(item => item.rollNo !== 0)} headers={headers} filename={"StudentsData.csv"}>
            Download as CSV
          </CSVLink>
        </Button>
      </div>
      <Divider />
      <div className="table">
        <Table
          columns={columns}
          tableData={filteredStudentsData}
          orderBy={orderBy}
          tableHeight="47vh" 
        />
      </div>
    </div>
  );
};

export default Students;
